<template>
  <div style="display: flex" class="goods-second">
    <div class="recommend">
      <div style="flex-direction:column;box-shadow: 2px 2px 20px #eee;padding-bottom: 10px">
        <div class="tit">精品推荐</div>
        <div v-for="(item,i) in recommends" :key="i" style="display: flex;margin: 20px 0">
          <router-link :to="`/goods/detail?goodsId=${item.sguid}`"  style="display: flex">
          <div style="margin:0 15px">
            <img v-if="item.img" :src="item.img" alt="" style="width: 60px;height: 60px;">
            <img v-if="!item.img" src="@/assets/img/home/pro1.jpg" alt  style="width: 60px;height: 60px;"/>
          </div>
          <div style="padding-right: 15px">
            <div style="margin-bottom: 5px">{{item.sgoodstitle }}</div>
            <div style="color: #ed2920">￥{{item.fprice}}</div>
          </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="list-pro-box clearfix" style="padding:0 0 10px 15px;width: 945px" >
      <div >
        <div :class="['pro-item','item-' + arrangeType]" :key="index" v-for="(item,index) in goodsList" v-if="arrangeType=='vertical'" style="margin-top: 0px">
          <!-- 纵向显示 -->
          <span class="is-guapai1" v-if="item.ispottype === 1"></span>
          <!-- <img class="camera-row" @click="toLinkCamera(item.sstoreid,item.swarehouseid)" v-if="item.ispottype && item.ispottype ===1" src="@/assets/img/home/camera.png" /> -->
          <div class="pro-pic" @click="toDetail(item)" style="cursor: pointer;margin-top: 20px;">
            <img v-if="item.img" :src="item.img+'?imageView2/1/w/160/h/160'" alt />
            <img v-if="!item.img" src="@/assets/img/home/pro1.jpg" alt />
            <div class="part">
              <!-- <p>品名/规格</p>
              <h2>{{item.scommodityname}}/{{item.sspec}}</h2> -->
            </div>
          </div>
          <div class="pro-des time-last" style="text-align: left;">
            <p class="pro-price">
<!--                <span style="color:#ed2920;font-size: 18px">-->
<!--                  <i class="s-over-price" v-if="item.ispottype == 2">¥{{item.fbargainprice}}</i>-->
<!--                  <i class="s-over-price" v-if="item.ispottype != 2">¥{{item.fprice}}</i>-->
<!--                  <i class="f12 " >/{{item.sweightunit}}</i>-->
<!--                </span>-->
              <span style="padding: 2px 5px;background: #feefdb;color: #ffa443;font-size: 12px;border-radius:4px">出售</span>
<!--              <span class="qiding-sty">{{item.fminbuyamount || '-'}}{{item.sweightunit}}起订 </span>-->
            </p>
            <div class="pro-tit" @click="toDetail(item)" style="cursor: pointer;">
              <!--                <p class="m-t-10 t-h-1 f-bold m-b-10" style="font-size: 16px;"> <span class="is-ziyin" v-if="item.imembertype && item.imembertype == 1">自营</span>{{item.sgoodstitle || item.scommodityname}}</p>-->
              <p class="m-t-10 t-h-1 f-bold m-b-10" style="font-size: 16px;">
<!--                <span class="is-ziyin" v-if=" item.igoodstype == 0">现货</span>-->
<!--                <span class="is-ziyin" v-if=" item.igoodstype == 1">二手</span>-->
<!--                <span class="is-ziyin" v-if=" item.igoodstype == 3">租赁</span>-->
<!--                <span class="is-ziyin" v-if=" item.igoodstype == 4">预售</span>-->
                {{item.sgoodstitle || item.scommodityname}}</p>
              <p class="f14 m-b-10 sover1" style="color:#666666;" :title="item.scommodityname+'|'+item.sspec">{{item.scommodityname}}｜{{item.sspec}}</p>
            </div>

            <!-- <p class="pro-price">
              <em class="min-buy-num" style="padding: 0;" v-if="item.fminbuyamount">起订量: &nbsp;{{item.fminbuyamount || '-'}}{{item.sweightunit}}</em>
            </p> -->
            <!-- <span class="is-ziyin" style="display: inline-block;" v-if="item.imembertype && item.imembertype == 1">自营</span> -->
            <a class="shoplink qiyename" >
              <img src="@/assets/img/qiyie-icon.png" alt="" >{{ item.smembername || item.sshopname }}
            </a>
<!--            <p class="publish" style="margin-top:5px;color:#AAAAAA;font-size:12px;">{{item.updatetime}}更新</p>-->
            <p class="count-down count-down-diff" v-if="item.ispottype == 2">
              <countdown :endTime="item.dtakedowntime"></countdown>
            </p>
            <div class="btn-group m-b-10">
              <div style="display: flex;justify-content: space-between">
                <p>价格：<span style="color:#ed2920;font-size: 17px;font-weight: 600">{{item.fprice || '价格免疫'}}/{{item.sweightunit}}</span></p>
                <p>数量：<span style="color:#ed2920;font-size: 17px;font-weight: 600">{{item.fweight}}</span></p>
              </div>
<!--              <el-button v-if="[0,2].includes( item.igoodstype)" class="btn1" title="加购物车" @click="addShopCar($event, item)" size="small" >加入购物车</el-button>-->
<!--              <el-button v-if="item.igoodstype==1" class="btn1" title="预约查看" @click="toDetail(item)" size="small" >预约查看</el-button>-->
<!--              <el-button class="btn2" title="加入对比" @click="compare($event, item, index)" :disabled="item.disableBtn" size="small" >加入对比</el-button>-->
            </div>
            <!-- <el-button title="进入店铺" @click="addShopCar(item)" size="mini" style="display: inline-block;padding: 5px 5px;font-size:12px;position: absolute;
            bottom: 0;right: 46px;border-radius: 0;border-right:none;border-bottom:none;"><i class="el-icon-house"></i></el-button> -->
          </div>
        </div>
      </div>
      <template v-if="arrangeType=='horizontal'" >
        <div style="background: #f0f0f0;display: flex;padding: 20px;">
          <span style="width: 350px;">商品信息</span>
          <span style="width: 250px;">产品规格</span>
          <span style="width: 200px;">价格</span>
        </div>
        <div style="height: 120px;padding: 20px" :class="['pro-item','item-' + arrangeType,'horiz-hover']" :key="index" v-for="(item,index) in goodsList">
          <!-- 列表显示 -->
          <span class="is-guapai1" v-if="item.ispottype === 1"></span>
          <div class="pro-pic" @click="toDetail(item)" style="cursor: pointer;border: 1px solid #F5F5F5;width: 82px;height:82px;">
            <img style="width:80px;height:80px;" v-if="item.img" :src="item.img+'?imageView2/1/w/160/h/160'" alt />
            <img v-if="!item.img" src="@/assets/img/home/pro1.jpg" alt style="width: 80px;" />
          </div>
          <div class="pro-des" style="text-align: left;padding: 8px 0 0 15px;">
            <div class="pro-tit" @click="toDetail(item)" style="cursor: pointer;width: 160px">
              <p class="m-t-10 t-h-1  m-b-10">
                <!--                <span class="is-ziyin" v-if="item.imembertype && item.imembertype == 1">自营</span>-->
                {{item.sgoodstitle || item.scommodityname}}
                <!--                <span style="font-size: 12px; border: 1px solid #ED2920; padding: 0 4px;height: 22px;line-height: 22px; color: #ED2920;margin-left: 10px;display: inline-block">{{item.fminbuyamount || '-'}}{{item.sweightunit}}起订 </span>-->
              </p>
              <p class="f13 m-b-10 qiyename"  style="font-size:12px;color:#666;display: inline-block;width:200px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"> <img src="@/assets/img/qiyie-icon.png" alt="" >{{ item.smembername || item.sshopname }}</p>
              <!-- <p class="f13 m-b-10">{{item.scommodityname}}/{{item.sspec}}</p> -->
            </div>
          </div>
          <div style="font-size:14px; color: #666666;width: 25%;margin: 20px 30px 0 80px;">
            <p>{{item.scommodityname}}｜{{item.sspec}} ｜{{item.gy}}</p>
            <p class="f13 m-t-10">库存：{{ item.fweight  + item.sweightunit}}</p>
          </div>
          <div style="width: 25%;margin-top: 20px;" class="time-last">
            <p class="pro-price">
              <span style="color:#ed2920;">
                 <i class="f20" v-if="item.ispottype == 2">¥{{item.fbargainprice}}</i>
                  <i class="f20 " v-if="item.ispottype != 2">¥{{item.fprice}}</i>
                <i class="f12 " >/{{item.sweightunit}}</i>
              </span>
            </p>
            <!--            <p class="publish" style="margin-top:5px;font-size: 12px;color: #666;" v-if="item.ispottype != 2">{{item.updatetime}}更新</p>-->
            <p class="count-down" v-if="item.ispottype == 2">
              <countdown :endTime="item.dtakedowntime"></countdown>
            </p>
          </div>
          <div class="btn-group m-b-10" style="width: 25%;float: right;margin-top: 20px;">
            <el-button v-if="[0,2].includes( item.igoodstype)" class="btn1" title="加购物车" @click="addShopCar($event, item)" size="small" >加购物车</el-button>
            <el-button v-if="item.igoodstype==1" class="btn1" title="预约查看" @click="toDetail(item)" size="small" >预约查看</el-button>
            <el-button class="btn2" title="加入对比" @click="compare($event, item, index)" :disabled="item.disableBtn" size="small" >加入对比</el-button>
          </div>
        </div>
      </template>
      <!-- <template v-if="arrangeType=='horizontal'">
        <el-table
          :data="goodsList"
          border
          style="width: 100%">
          <el-table-column
          width="120"
            prop="sgoodstitle"
            label="商品名称"
            sortable
            >
          </el-table-column>
          <el-table-column
            prop="sspec"
            label="规格"
            sortable
            >
          </el-table-column>
          <el-table-column
          width="120"
            prop="proportion"
            label="原料配比"
            sortable
            >
          </el-table-column>
          <el-table-column
          width="150"
            prop="purpose"
            label="用途"
            sortable
            >
          </el-table-column>
          <el-table-column
          width="180"
            prop="swarehouse"
            label="仓库/到港地"
            sortable
            >
          </el-table-column>
          <el-table-column
            prop="sshopname"
            label="商家"
            >
          </el-table-column>
          <el-table-column prop="fprice" label="价格" width="120" align="center" sortable>
            <template slot-scope="scope">
              <p class="p-color">{{ scope.row.fprice + '元/' + scope.row.sweightunit}}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="fweight"
            label="库存"
            align="center"
            width="110">
            <template slot-scope="scope">
              <p>{{ scope.row.fweight  + scope.row.sweightunit}}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="fminbuyamount"
            label="最小购买量"
            align="center"
            width="100">
          </el-table-column>
          <el-table-column
          width="180"
            prop="dcheckdate"
            label="更新日期"
            align="center"
            >
          </el-table-column>
          <el-table-column
            label="操作"
            fixed="right"
            width="140">
            <template slot-scope="scope">
              <el-button-group>
                <el-button @click="toDetail(scope.row)" type="text" style="padding:0" size="small">查看</el-button>
                <el-button @click="compare($event, scope.row, scope.$index)" :disabled="scope.row.disableBtn" style="margin: 0 10px;padding: 0;" type="text" size="small">对比</el-button>
                <el-button @click="toLinkCamera(scope.row.sstoreid,scope.row.swarehouseid)" v-if="scope.row.ispottype && scope.row.ispottype ==1" style="padding:0;margin-right: 0;" type="text" size="small">监控</el-button>
              </el-button-group>
            </template>
          </el-table-column>
        </el-table>
      </template> -->
      <div style="text-align:center;line-height:200px;width:1200px" v-if="goodsList.length < 1">未查询到任何商品信息...</div>
    </div>
  </div>
</template>

<script>
import goodsApi from '../../api/goodsApi'
import cartApi from '../../api/cartApi'
import ShopCar from '../../assets/script/ShopCar'
import LoginUser from '../../assets/script/LoginUser'
import countdown from '../../components/countdown'
export default {
  components: {
    countdown: countdown
  },
  props: {
    goodsList: Array,
    arrangeType: {
      type: String,
      default: 'vertical'
    }
  },
  data () {
    return {

      recommends: [],
      compareNum: 0,
      compareids: [],
      scommoditysystemcode: ''
    }
  },
  watch: {
    goodsList: 'goodsListChange'
  },
  created () {
    if (localStorage.getItem('COMPARE_GOODS')) {
      this.compareids = JSON.parse(localStorage.getItem('COMPARE_GOODS')).concat()
      this.compareNum = this.compareids.length
      this.$store.commit('setCompareNum', this.compareNum)
      if (this.compareNum > 0) {
        this.scommoditysystemcode = this.compareids[0].scommoditysystemcode
      }
    }
    this.$bus.$on('deleteTags', this.deleteTags)
    this.loadReCommends();
  },
  mounted () {

  },
  methods: {
    async loadReCommends() {
      const res = await goodsApi.getHots({pageSize: 5});
      this.recommends = res.list
      this.recommends.forEach( x=>{
        const json = JSON.parse(x.sjson);
        if (json.imgs && json.imgs.length != 0) {
          x.img = json.imgs[0].url;
        }
      })

    },
    goodsListChange (goodlist) {
      if (this.compareids.length > 0) {
        this.compareids.map((g) => {
          var tempgood = this.goodsList.find((item) => item.sguid === g.sguid)
          if (tempgood) {
            tempgood.disableBtn = true
            this.scommoditysystemcode = tempgood.scommoditysystemcode
          }
        })
      }
    },
    deleteTags (tag) {
      for (const i in this.goodsList) {
        if (this.goodsList[i].sguid === tag.sguid) {
          this.goodsList[i].disableBtn = false
          this.$set(this.goodsList, i, this.goodsList[i])
          // this.compareids.splice(this.compareids.indexOf(this.goodsList[i].sguid), 1)
          this.compareids = this.compareids.filter((item) => { return item.sguid !== tag.sguid })
          localStorage.setItem('COMPARE_GOODS', JSON.stringify(this.compareids))
          this.compareNum--
          this.$store.commit('setCompareNum', this.compareNum)
          if (this.compareNum === 0) {
            this.scommoditysystemcode = ''
            this.$bus.$emit('showCompareIcon', false)
            this.$bus.$emit('showCompareList', false)
          }
        }
      }
    },
    compare (e, item, index) {
      e.stopPropagation()
      if (this.$store.getters.getCompareNum <= 4) {
        if (this.$store.getters.getCompareNum === 0) {
          this.scommoditysystemcode = item.scommoditysystemcode
        }
        if (item.scommoditysystemcode === this.scommoditysystemcode) {
          if (this.compareids.find((temp) => { return temp.sguid === item.sguid })) {
            this.$message.error('此商品已包含在对比列表，请不要重复添加！')
          } else {
            this.goodsList[index].disableBtn = true
            this.$set(this.goodsList, index, this.goodsList[index])
            this.compareids.push(item)
            this.$bus.$emit('compareGoods', item)
            this.$bus.$emit('showCompareIcon', true)
            this.$bus.$emit('showCompareList', true)
            localStorage.setItem('COMPARE_GOODS', JSON.stringify(this.compareids))
            this.compareNum++
            this.$store.commit('setCompareNum', this.compareNum)
          }
        } else {
          this.$message.error('请选择同品名的商品进行对比！')
        }
      } else {
        this.$message.error('最多同时对比五件商品！')
      }
    },
    toDetail (item) {
      this.$emit('toDetail', item)
    },
    /**
     * 查看监控
     */

    toLinkCamera (sstoreid, swarehouseid) {
      goodsApi.querycamera({
        id: sstoreid,
        swarehouseid: swarehouseid
      }).then(data => {
        if (data) {
          if (data.code === '000000') {
            window.open(data.data)
          } else {
            this.$myTip.warning(data.msg)
          }
        } else {
          this.$myTip.warning('未找到摄像头！')
        }
      }).catch(error => {
        console.error('查看摄像头遇到异常:', error)
      })
    },
    addShopCar (e, item) {
      if (LoginUser.isLogin()) {
        cartApi.add({
          goodsId: item.sguid, ibuycount: item.fminbuyamount
        }).then(data => {
          ShopCar.length = data
          this.$myTip.success('添加购物车成功!')
        }).catch(error => {
          console.error('添加商品到购物车遇到异常:', error)
          this.$myTip.error('添加失败!', error)
        })
      } else {
        this.$router.push('/login')
      }
    }
  }
}
</script>
<style lang="scss">

.indexTab {
  width: 90%;
  height: 45px;
  .el-tabs__nav-wrap::after {
    background-color: transparent;
  }
  .el-tabs__nav-scroll{
    margin-left: 20px;
  }
  .el-tabs__active-bar{
    background-color: #40aceb;
  }
  .el-tabs__nav .el-tabs__item.is-active{
    color: #40aceb;
    background: none;
  }
}

.btn-group {
  text-align: center;
  margin-top: 9px;
  .btn1{
    color: #fff;
    background-color: #ED2920;
    border-color: #ED2920;
    height: 32px;
    padding: 5px 10px;

  }
  .btn1:hover{
    background-color: #fd855a;
    border-color: #fd855a;
  }
  .btn1:focus{
    background-color: #fd855a;
    border-color: #fd855a;
  }
  .btn2{
    border: 1px solid #f5f5f5;
    padding: 5px 10px;
    height: 32px;
    color: #666666;
    background-color: #f9f9f9;
    //border-color: #f9f9f9;
  }
  .btn2:hover{
    background-color: #ffecc7;
    border-color: #ffecc7;
  }
  .btn2:focus{
    background-color: #fdb11f;
    border-color: #fdb11f;
  }
}
.index-product {
  text-align: center;
  background: #f9f9f9;
  .pro-box {
    display: flex;
  }
  .pro-item {
    cursor: default;
  }
  a.shoplink:hover {
    color:#d0111a !important;
  }
  position: relative;
  /*margin-top: 20px;*/
  .pro-ad {
    width: 262px;
    /*margin-right: 28px;*/
    height: 456px;
    cursor: pointer;
    // img {
    //   height: 100%;
    //   width: 100%;
    // }
  }
  .pro-title {
    font-size: 16px;
  }
  .tags {
    padding: 0 2px;
    box-sizing: border-box;
    border: 1px solid #f7d6d8;
    background: #f7d6d8;
    color: #d0111a;
    font-size: 12px;
    display: inline-block;
  }
}
</style>
<style lang="scss" scoped>
.goods-second{

.list-pro-box .pro-item .camera-row {
  right: -2px;
  top: 0;
  bottom: -280px;
  width: 24px !important;
}

.el-button.is-disabled {
  pointer-events:none;
}
.list-pro-box {
  //padding: 10px;
  //padding-top: 0;
  /*border: 1px solid #e7e7e7;*/
  background: #fff;
  .pro-item {
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    margin: 10px 0 20px 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    border: solid 1px #f5f5f5;
    background: #fff;
    .pro-tit {
      height: 65px;
    }
    .pro-pic {
      width: 100%;
      position: relative;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 168px;
    }
    .pro-des {
      padding: 15px;
    }
    img {
      width: 80%;
      display: block;
      margin: auto;
      height: 100%;
    }
    &.item-horizontal {
      padding-bottom: 15px;
      display: flex;
      width: 100%;
      border-bottom: solid 1px #e7e7e7;
      border: none;
      margin-bottom: 15px;
      cursor: pointer;
      .pro-pic {
        width: 160px;
        height: 120px;
      }
    }
    &.item-vertical {
      width: 222px;
      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        border-radius: 6px;
      }

      &:nth-child(5n + 0) {
        margin-right: 0;
      }
      .pro-pic {
        padding-top: 15px;
      }
    }
  }

}
.count-down-diff{
  position: absolute;
  left: 10px;
  top:0
}
.time-last .count-down{
  margin-top: 10px;
  font-size: 12px;
}
.time-last .count-down span{
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background: #1979ff;
  border-radius: 3px;
  padding: 4px 5px;
}
.qiding-sty{
  font-size: 10px;
  border: 1px solid #ED2920;
  padding: 0 4px;
  height: 18px;
  line-height: 18px;
  color: #ED2920;
  margin-left:10px;
  display: inline-block
}
.qiyename{
  position: relative;
  padding-left: 30px;
  font-size:12px;
  color:#666;
  display: inline-block;
  margin-top: 8px;
  width:200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  img{
    width: 25px !important;
    margin: 0;
    position: absolute;
    left: 0;
    top: -1px;
    height: auto !important;
  }
}
.horiz-hover:hover{
  background: #fff4f4;
}
.recommend{
  width: 255px;
  background: #fff;
  .tit{
    height: 55px;
    line-height: 55px;
    text-align: center;
    background: linear-gradient( to top,#fff,#FFECEC);
    font-size: 16px;
  }
}

}
</style>
